type Environment = 'development' | 'production';

type AppConfig = {
	environment: Environment;
	sentryEnabled: boolean;
	sitePasswordEnabled: boolean;
};

const devConfig: AppConfig = {
	environment: 'development',
	sentryEnabled: false,
	sitePasswordEnabled: false
};

const prodConfig: AppConfig = {
	environment: 'production',
	sentryEnabled: true,
	sitePasswordEnabled: true
};

export const appConfig: AppConfig = import.meta.env.PROD ? prodConfig : devConfig;
