import * as Sentry from '@sentry/sveltekit';
import { appConfig } from '../../app.config';

type SentryInitParams = Parameters<typeof Sentry.init>[0];

export const baseSentryConfig: SentryInitParams = {
	enabled: appConfig.sentryEnabled,
	environment: appConfig.environment,
	dsn: 'https://3614f7cb422a88a8e176c9c37ae39133@o4506512072769536.ingest.sentry.io/4506512075194368',
	tracesSampleRate: 1.0
};
